import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import iconofrico from "../../../images/Fricongelados-01.png";
import "./consult.css";
import { generate_veritication_code, verification_code } from '../../../services/services';
import img from "../../../images/helado.gif";
import AWS from 'aws-sdk';
import Auth from '@aws-amplify/auth'

export const CONSULT_FOLIO = (props) => {

  const [folio, setfolio] = useState("");
  const [email, setemail] = useState("");
  const [codev, setcodev] = useState("");
  const [verificacion, setverificacion] = useState(false);
  const [load, setload] = useState(false);
  const [load2, setload2] = useState(false);
  const [unlock, setunlock] = useState(false);
  const [data, setdata] = useState([]);

    const configureAWSCredentials = (credentials) => {
        AWS.config.update({
            region: 'us-east-1',
            credentials: new AWS.Credentials({
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            }),
        });
    };

  const solict_code = async() =>{    

    if(email?.trim() === "" || email === null || folio?.trim() === "" || folio === null){
        alert("Parámetros Faltantes");
        return null;
    }

    var body ={
        email: email, 
        folio: folio
    }
    setload(true);
    try{
        // const d = await generate_veritication_code(dat);
        // console.log(d);
        const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/web/application/send`;
        const request = new AWS.HttpRequest(url, AWS.config.region);
        request.method = "POST";
        request.headers['host'] = new URL(url).host;
        request.headers['Content-Type'] = 'application/json';
        request.body = JSON.stringify(body);
        const signer = new AWS.Signers.V4(request, 'execute-api');
        signer.addAuthorization(AWS.config.credentials, new Date());
        const response = await fetch(url, {
            method: request.method,
            headers: request.headers,
            body: request.body ? request.body : null,
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Datos recibidos:", data);
            setload(false);
            setverificacion(true);
        } else {
            setload(false);
            console.error("Error en la respuesta de la API:", response.status, response.statusText);
        }
    }catch(err){
        setload(false);
        console.log(err);
        alert(err?.response?.data?.errorMessage?.es ?  err?.response?.data?.errorMessage?.es: "Error al solicitar el código de verificación, vuelve a intentar más tarde");
    }
  }

  const verificar = async () => {
    setload2(true);
    var body ={
        folio: folio,
        email: email,
        verification_code: codev
    }
    try{
        // const d = await verification_code(dat);
        // console.log(d);
        // setdata(d?.data?.data);

        const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/web/application/check`;
        const request = new AWS.HttpRequest(url, AWS.config.region);
        request.method = "POST";
        request.headers['host'] = new URL(url).host;
        request.headers['Content-Type'] = 'application/json';
        request.body = JSON.stringify(body);
        const signer = new AWS.Signers.V4(request, 'execute-api');
        signer.addAuthorization(AWS.config.credentials, new Date());
        const response = await fetch(url, {
            method: request.method,
            headers: request.headers,
            body: request.body ? request.body : null,
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Datos recibidos:", data);
            setdata(data?.data);
            setload2(false);
            setunlock(true);
        } else {
            setload2(false);
            console.error("Error en la respuesta de la API:", response.status, response.statusText);   
        }        
    }catch(err){
        console.log(err);
        setload2(false);
        alert(err?.response?.data?.errorMessage?.es ?  err?.response?.data?.errorMessage?.es: "Error al verificar el código, vuelve a intentar más tarde");
   
    }
  }

    
  const handleLoginSuccess = async (googleToken) => {
    try {
        console.log('Token de Google:', googleToken);       
        sessionStorage.setItem('googleToken', googleToken);

        const credentials = await Auth.federatedSignIn(
            'accounts.google.com',
            { token: googleToken},
            "Usuario Google"
        );
        configureAWSCredentials(credentials);
    } catch (error) {
        setload(false);
        console.error("Error al autenticar en AWS Cognito:", error);
        window.location = "/labor_exchange"
        
    }
};

  
  const checkSession = async () => {
    const storedToken = sessionStorage.getItem('googleToken'); 
    if (storedToken) {
        try {
            await handleLoginSuccess(storedToken);
        } catch (error) {
            console.error("Token inválido o expirado:", error);
            sessionStorage.removeItem('googleToken');
        }
    }else{
        setload(false);
    }
};
  useEffect(() => {
    checkSession();
}, []);


  return (
    <div className=''>
        <img className="frico-icon-logo" alt='frico-icon' src={iconofrico}></img>
        {
            load===true?
                <div className='loading-carts-jobs'>
                    <img src={img} alt=''></img>
                    <h2>Cargando ...</h2>
                </div>:
            load2 === true?
                <div className='loading-carts-jobs'>
                    <img src={img} alt=''></img>
                    <h2>Verificando ...</h2>
                </div>:
            unlock==true?
                <div className="">
                    <div className='info-solicitante'>
                        <p><span>Fecha Solicitud:</span> {data?.application_date}</p>
                        <p><span>Folio:</span> {data?.folio}</p>
                        <p><span>Estado:</span> {data?.status==="Pending"?"PENDIENTE":
                                                    data?.status==="Under Review"?"EN REVISIÓN":
                                                        data?.status==="Interview Scheduled"?"ENTREVISTA PROGRAMADA":
                                                            data?.status==="Accepted" ? "ACEPTADA":
                                                                data?.status==="Rejected" ? "RECHAZADA" : ""
                                                }
                        </p>
                        <p className='text-gray' style={{color:"gray"}}>
                            {data?.status==="Pending"?"La solicitud ha sido recibida, pero aún no ha sido revisada.":
                                data?.status==="Under Review"?"La solicitud está siendo evaluada por el equipo de selección.":
                                    data?.status==="Interview Scheduled"?"El candidato ha sido seleccionado para una entrevista.":
                                        data?.status==="Accepted" ? "La solicitud ha sido aprobada y el candidato es seleccionado para la vacante.":
                                            data?.status==="Rejected" ? "La solicitud fue rechazada." : ""
                            }
                        </p>
                        {
                            data.interview_date === "" || data?.interview_date === null ? <></>:
                            <p><span>Entrevista programada:</span> {data?.interview_date}</p>
                        }
                        <p><span>Notas: </span> {data?.notes===""?"Ninguna":data?.notes}</p>
                        <div className='button-solict-code-applicants'>
                            <button onClick={()=>window.location="/labor_exchange"}>Regresar</button>
                        </div>
                    </div>
                </div>:
            verificacion===true?
                <div className='grid-verification-code'>
                    <p>
                        Se ha enviado un código de verificación al correo electrónico que proporcionaste. 
                        Por favor, ingrésalo a continuación para poder visualizar el estatus de tu solicitud de vacante.
                    </p>
                    <div className=''>
                        <TextField
                            label="Código de Verificación"
                            required
                            value={codev}
                            sx={{ m: 1.5, width: "100%" }}
                            onChange={(event) => {
                                const value = event.target.value;
                                if (/^\d*$/.test(value)) { // Solo permite números
                                    setcodev(value);
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px"
                                }
                            }}
                            inputProps={{
                                maxLength: 6
                            }}
                            helperText={
                                codev && !/^\d*$/.test(codev)
                                    ? "Por favor, ingresa solo números."
                                    : ""
                            }
                        ></TextField>
                    </div>
                    
                    <div className='button-solict-code-applicants'>
                        <button onClick={()=>verificar()}>Verificar</button>
                        <button onClick={()=>props?.setconsult(false)}>Cancelar</button>
                    </div>
                </div>:
                <div className='grid-folio-consult-status'>
                    <div className=''>
                        <TextField
                            label="Folio"
                            required
                            value={folio}
                            sx={{ m: 1.5, width: "100%" }}
                            onChange={(event) => {
                                const value = event.target.value;
                                if (/^[A-Za-z]{0,2}(-)?(\d*)?$/.test(value)) {
                                    setfolio(value);
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                            }}
                            inputProps={{
                                maxLength: 6
                            }}
                            helperText={
                                folio && !/^[A-Za-z]{2}-[1-9]\d*$/.test(folio)
                                    ? "Formato: XX-{número}, donde XX son dos letras y el número no comienza con 0."
                                    : ""
                            }
                            error={folio && !/^[A-Za-z]{2}-[1-9]\d*$/.test(folio)} // Marca error si no cumple la validación final
                        
                        
                        ></TextField>
                    </div>
                    <div className=''>
                        <TextField
                            label="Correo Electrónico"
                            value={email}
                            required
                            sx={{ m: 1.5, width: "100%" }}
                            onChange={(event) => setemail(event.target.value)}  
                            InputLabelProps={{ 
                                shrink: true, 
                                style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                            }}
                            inputProps={{
                                maxLength: 80,
                                pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$" // Expresión regular para validar el formato del correo
                            }}
                            helperText={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) && email ? "Por favor ingrese un correo válido" : ""}
                            error={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) && email}                                    
                           
                        ></TextField>
                    </div>
                    <div className='button-solict-code-applicants'>
                        <button onClick={()=>solict_code()}>Siguiente</button>
                        <button onClick={()=>props?.setconsult(false)}>Cancelar</button>
                    </div>
            </div>
        }    
    </div>
  )
}
