import React, { useEffect, useState } from 'react'
import TextField from "@mui/material/TextField";
import iconofrico from "../../../images/Fricongelados-01.png";
import { post_application, upload_file } from '../../../services/services';
import { MenuItem } from '@mui/material';
import giff from "../../../images/helado.gif";
import { CONSULT_FOLIO } from './consultfolio';
import {CustomDialog } from "react-st-modal";
import AWS from 'aws-sdk';
import Auth from '@aws-amplify/auth'

export const FORM_JOB_OPP = (props) => {

    const configureAWSCredentials = (credentials) => {
        AWS.config.update({
            region: 'us-east-1',
            credentials: new AWS.Credentials({
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            }),
        });
    };

    const [ candidate_name, setcandidate_name] = useState("");
    const [ candidate_email, setcandidate_email ] = useState("");
    const [ candidate_phone, setcandidate_phone ] = useState("");
    const [ availability, setavailability] = useState("");
    const [cover_letter, setcover_letter] = useState("");
    const [portfolio_url, setportfolio_url] = useState("");
    const [base64, setbase64] = useState("");
    const [folio, setfolio] = useState("");
    const [source, setsource] = useState("WEB");
    const [expected_salary, setexpected_salary] = useState("");
    const [years_of_experience, setyears_of_experience] = useState("");
    const [preferred_contact_method, setpreferred_contact_method] = useState("AMBOS");

    const [load, setload] = useState(false);
    const [form, setform] = useState(false);
    const [suceess, setsuceess] = useState(false);
    const [consult, setconsult] = useState(false);
   
    const editable = async() =>{
        await CustomDialog(
            <div className='pdf-create-aspirant'>
                    <div className='tutorial-pdf-edit'>
                        <p>
                            Se habilitará un archivo PDF editable para que el usuario complete los campos correspondientes. 
                            Una vez finalizada la edición, se recomienda descargar el archivo utilizando el botón de 
                            "Imprimir". 
                            <br></br>
                            Al hacer clic en este botón, seleccione la opción 'Guardar como PDF' en el campo de destino. Finalmente, 
                            suba el archivo descargado nuevamente en el módulo.
                        </p>
                    </div>
                    <iframe src="https://fricongelados-multimedia.s3.us-east-1.amazonaws.com/SOLICITUD_VACANTE.pdf" title="PDF document" width="100%" height="600px"/>
            </div>
        ,
       {
           className: "custom-modal-pdf",
           title: "EDITAR PDF",
           showCloseIcon: true,
       }
       );
    }

    const handleFileUpload = (file, fileInput) => {
        if (file && file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(",")[1];
                console.log("Base64 String:", base64String);
                setbase64(base64String);
            };
            reader.onerror = (error) => {
                console.error("Error al leer el archivo:", error);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Error: Solo se permiten archivos en formato PDF.");
            fileInput.value = "";
        }
    };

    const post_handle = async () =>{

        if(candidate_name?.trim() ==="" || candidate_email?.trim() === "" || candidate_phone?.trim() === "" || availability?.trim() === "" || cover_letter?.trim()===""){
            alert("LLena todos los campos obligatorios");
            return null;
        }
        if(base64===""){
            alert("No ha proporcionado el archivo PDF");
            return null;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(candidate_email)) {
            alert("Por favor, ingresa un correo válido.");
            return null;
        }
        const phoneRegex = /^\d{10}$/; // Acepta exactamente 10 dígitos
        if (!phoneRegex.test(candidate_phone)) {
            alert("Por favor, ingresa un número de teléfono válido (10 dígitos).");
            return null;
        }



        var body = {
            job_id: props?.data?.job_id,
            candidate_name:  candidate_name,
            candidate_email: candidate_email,
            candidate_phone:  candidate_phone,
            availability: availability,
            source: source,
            expected_salary: expected_salary,
            preferred_contact_method: preferred_contact_method === "AMBOS" ? null : preferred_contact_method,
            years_of_experience: years_of_experience,
            cover_letter: cover_letter
        }
        try{
            setload(true);
            const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/web/application`;
            const request = new AWS.HttpRequest(url, AWS.config.region);
            request.method = "POST";
            request.headers['host'] = new URL(url).host;
            request.headers['Content-Type'] = 'application/json';
            request.body = JSON.stringify(body);
            const signer = new AWS.Signers.V4(request, 'execute-api');
            signer.addAuthorization(AWS.config.credentials, new Date());
            const response = await fetch(url, {
                method: request.method,
                headers: request.headers,
                body: request.body ? request.body : null,
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log("Datos recibidos:", data);
                setfolio(data?.folio);
                upload_cv(data?.application_id); 
            } else {
                const errorData = await response.json();
                console.error(errorData?.errorMessage);
                if(errorData?.errorMessage){
                    alert(errorData?.errorMessage?.es);
                }else{
                    alert("Error al registrar");
                }
                console.error("Error en la respuesta de la API:", response.status, response.statusText);
                console.log(response);
                setload(false);
            }              
        }catch(err){
            console.log(err);
            setload(false);
            alert(err?.response?.data?.errorMessage?.es ?  err?.response?.data?.errorMessage?.es: "Error al enviar el formulario, vuelve a intentar más tarde");
        }
    }

    
    const handleLoginSuccess = async (googleToken) => {
        try {
            console.log('Token de Google:', googleToken);       
            sessionStorage.setItem('googleToken', googleToken);

            const credentials = await Auth.federatedSignIn(
                'accounts.google.com',
                { token: googleToken},
                "Usuario Google"
            );
            configureAWSCredentials(credentials);
        } catch (error) {
            setload(false);
            console.error("Error al autenticar en AWS Cognito:", error);
            window.location = "/labor_exchange"
            
        }
    };

    const checkSession = async () => {
        const storedToken = sessionStorage.getItem('googleToken'); 
        if (storedToken) {
            try {
                await handleLoginSuccess(storedToken);
            } catch (error) {
                console.error("Token inválido o expirado:", error);
                sessionStorage.removeItem('googleToken');
            }
        }else{
            setload(false);
        }
    };
    
    useEffect(() => {
        checkSession();
    }, []);

    const upload_cv = async(id) =>{
        var body ={
            application_id: id,
            job_id: props?.data?.job_id,
            document_data: base64
        }
        try{
            const url = `https://hq8d2hivua.execute-api.us-east-1.amazonaws.com/development/web/document`;
            const request = new AWS.HttpRequest(url, AWS.config.region);
            request.method = "POST";
            request.headers['host'] = new URL(url).host;
            request.headers['Content-Type'] = 'application/json';
            request.body = JSON.stringify(body);
            
            const signer = new AWS.Signers.V4(request, 'execute-api');
            signer.addAuthorization(AWS.config.credentials, new Date());
            const response = await fetch(url, {
                method: request.method,
                headers: request.headers,
                body: request.body ? request.body : null,
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log("Datos recibidos:", data);
                setbase64("");
                setcandidate_name("");
                setcandidate_email("");
                setcandidate_phone("");
                setavailability("");
                setportfolio_url("");
                setcover_letter("");
                setyears_of_experience("");
                setexpected_salary("");
                setload(false);
                setsuceess(true);
            } else {
                setload(false);
                console.error("Error en la respuesta de la API:", response.status, response.statusText);
            }
           
        }catch(err){
            setload(false);
            alert("Error al subir el CV");
        }
    }

  return (
    <>
    <div className='form-content-user'>
        {
            load===true?
                <div className='load-sent-applicants-gif'>
                    <img className='' alt='gif frico' src={giff}></img>
                    <p>Enviando Solicitud</p>
                </div>:
            suceess===true?
                <div className='success-application'>
                     <img className="frico-icon-logo" alt='frico-icon' src={iconofrico}></img>
                     <p>
                        Su solicitud ha sido enviada a Fricongelados y recibirá una respuesta pronta. A continuación, le proporcionamos su folio de seguimiento. Es importante que lo conserve, ya que lo necesitará para futuras consultas o aclaraciones.
                     </p>
                     <p className='folio-resp'>{folio}</p>
                     <div className='back-form-labor_change'>
                        <button className='button-solict-2' onClick={()=>window.location="/labor_exchange"}>Regresar</button>
                     </div>
                </div>:
            consult===true?
                <CONSULT_FOLIO setconsult={setconsult}></CONSULT_FOLIO>:
            form===true?
                <div className='form-content'>
                    <img className="frico-icon-logo" alt='frico-icon' src={iconofrico}></img>
                    <div className='grid-labor'>
                            <div className=''>
                                <div className=''>
                                    <TextField
                                        label="Nombre Completo"
                                        required
                                        value={candidate_name}
                                        sx={{ m: 1.5, width: "100%" }}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            if (/^[a-zA-ZÀ-ÿ\s]*$/.test(value)) { // Permite solo letras y espacios
                                                setcandidate_name(value);
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                                        }}
                                        inputProps={{
                                            maxLength: 80
                                        }}
                                        helperText={
                                            candidate_name && !/^[a-zA-ZÀ-ÿ\s]*$/.test(candidate_name)
                                                ? "El nombre solo debe contener letras y espacios"
                                                : ""
                                        }
                                    ></TextField>
                                </div>
                                <div className=''>
                                    <TextField
                                        label="Correo Electrónico"
                                        value={candidate_email}
                                        required
                                        sx={{ m: 1.5, width: "100%" }}
                                        onChange={(event) => setcandidate_email(event.target.value)}  
                                        InputLabelProps={{ 
                                            shrink: true, 
                                            style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                                        }}
                                        inputProps={{
                                            maxLength: 80,
                                            pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$" // Expresión regular para validar el formato del correo
                                        }}
                                        helperText={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(candidate_email) && candidate_email ? "Por favor ingrese un correo válido" : ""}
                                        error={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(candidate_email) && candidate_email}                                    
                                        ></TextField>
                                </div>
                                <div className=''>
                                    <TextField
                                        label="Número Telefónico"
                                        required
                                        value={candidate_phone}
                                        sx={{ m: 1.5, width: "100%" }}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            if (/^\d*$/.test(value)) {  // Solo permite dígitos
                                                setcandidate_phone(value);
                                            }
                                        }} 
                                        InputLabelProps={{ shrink: true, style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" } }}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        error={candidate_phone && candidate_phone.length !== 10} // Marca error si no tiene 10 dígitos
                                        helperText={
                                            candidate_phone && candidate_phone.length !== 10
                                                ? "El número debe tener exactamente 10 dígitos"
                                                : ""
                                        }
                                    ></TextField>
                                </div>
                                <div className='grid-forms-applicants'>
                                    <div className=''>
                                        <TextField
                                            label="Disponibilidad (Inmediata, etc)"
                                            value={availability}
                                            required
                                            sx={{ m: 1.5, width: "100%" }}
                                            onChange={(event) => setavailability(event.target.value)}  
                                            InputLabelProps={{ shrink: true, style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" } }}
                                            inputProps={{
                                                maxLength: 60
                                            }}
                                        ></TextField>
                                    </div>
                                    <div className=''>
                                        <TextField
                                            label="Contacto de Preferencia"
                                            value={preferred_contact_method}
                                            select
                                            sx={{ m: 1.5, width: "100%", textAlign: "left" }}
                                            onChange={(event) => setpreferred_contact_method(event.target.value)}  
                                            InputLabelProps={{ shrink: true, style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" } }}
                                    
                                        >
                                            {
                                            [
                                                {label: "Ambos", value: "AMBOS"},
                                                {label: "Correo Electrónico", value: "correo"},
                                                {label: "Teléfono", value: "telefono"}
                                            ].map((option, key) => (
                                                <MenuItem key={key} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </div>
                                </div>
                                <div className='grid-forms-applicants'>
                                    <div className=''>
                                        <TextField
                                            label="Años de Experiencia (Opcional)"
                                            value={years_of_experience}
                                            sx={{ m: 1.5, width: "100%" }}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d{0,2}$/.test(value)) { // Permite solo hasta 2 dígitos positivos
                                                    setyears_of_experience(value);
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                                            }}
                                            inputProps={{
                                                maxLength: 2 // Limita el número de caracteres a 2
                                            }}
                                            helperText={
                                                years_of_experience && !/^\d{0,2}$/.test(years_of_experience)
                                                    ? "Por favor, ingresa solo números enteros positivos."
                                                    : ""
                                            }
                                        ></TextField>
                                    </div>
                                    <div className=''>
                                        <TextField
                                            label="Salario Esperado (Opcional)"
                                            value={expected_salary}
                                            sx={{ m: 1.5, width: "100%" }}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(value)) { // Permite solo números enteros
                                                    setexpected_salary(value);
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px"
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 8
                                            }}
                                            helperText={
                                                expected_salary && !/^\d*$/.test(expected_salary)
                                                    ? "Por favor, ingresa solo números enteros."
                                                    : ""
                                            } ></TextField>
                                    </div>
                                </div>
                                <div className=''>
                                    <TextField
                                        label="Carta de Presentación"
                                        required
                                        value={cover_letter}
                                        sx={{ m: 1.5, width: "100%" }}
                                        multiline
                                        rows={3} 
                                        onChange={(event) => setcover_letter(event.target.value)}  
                                        InputLabelProps={{ shrink: true, style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" } }}
                                    ></TextField>
                                </div>
                                <div className='pdf-editable'>
                                    <p>Si no cuenta con su Solicitud Laboral, puede completar una nueva haciendo <span onClick={()=>{editable()}}>Clic Aquí.</span></p>
                                </div>
                                <div className=''>
                                    <TextField
                                        label="Subir CV o Solicitud Laboral (PDF)"
                                        type="file"
                                        sx={{ m: 1.5, width: "100%" }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: '1.3rem', backgroundColor: "white", paddingRight: "10px" }
                                        }}
                                        InputProps={{
                                            inputProps: { accept: "application/pdf" }
                                        }}
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            handleFileUpload(file, event.target);
                                        }}
                                    />
                                </div>
                                <div className='button-solict'>
                                    <button onClick={()=>post_handle()}>Solicitar</button>
                                    <button onClick={()=>setform(false)}>Cancelar</button>
                                </div>
                            </div>

                        </div>

                </div>:
                <div className='crud-user-info'>
                    <div className='options-user'>
                        <img className="frico-icon-logo" alt='frico-icon' src={iconofrico}></img>
                        <h2>Bienvenido Usuario</h2>
                        <div className='consult-vac'>
                            <p>
                                Si ya has postulado a esta vacante y deseas ver o actualizar tu información, 
                                haz clic en el botón "Consultar mi solicitud".
                            </p>
                            <p>
                                Si eres un nuevo aspirante, por favor haz clic en el botón "Solicitar vacante".
                            </p>
                               
                        </div>
                        <div className='register-vacante'>
                            <button onClick={()=>setconsult(true)}>
                                Consultar mi Solicitud
                            </button> 
                            <button onClick={()=>setform(true)}>
                                Solicitar Vacante
                            </button>
                        </div>
                    </div>
                </div>
        }
                

            </div>

    </>
  )
}
